





















import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Inject, logger } from '../../../support'

import { IProductsRepository, ProductsRepositoryType } from '../../products/contracts/repositories'
import {
  translateProductToProductCard
} from '../../products/organisms/ProductCard/ProductCard.helpers'
import { ProductCardProps } from '../../products/organisms/ProductCard/ProductCard.contracts'
import { ProductCard } from '../../products/organisms/ProductCard'

import { BaseWishListMixin, IBaseWishListMixin } from '../shared/mixins/base.mixin'
import { EmptyWishlist } from '../molecules/Empty.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Wishlist>({
  name: 'Wishlist',
  components: { EmptyWishlist, ProductCard },
  mounted (): void {
    if (this.wishlist) {
      this.fetchProductsOfWishlist()
    }
  }
})
export class Wishlist extends Mixins<IBaseWishListMixin>(BaseWishListMixin) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  public isLoading: boolean = false
  public products: ProductCardProps[] = []

  public get hasProducts (): boolean {
    return Array.isArray(this.products) && this.products.length > 0
  }

  /**
   * Fetches the actual products of wishlist.
   */
  public async fetchProductsOfWishlist (): Promise<void> {
    if (!this.wishlist) {
      return
    }

    const skus: string[] = this.wishlist.items.map((item) => item.product.sku)

    try {
      this.isLoading = true
      const response = await this.productsRepository.loadProductsBySkus(skus)
      this.products = response.map((p) => translateProductToProductCard(p))
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.isLoading = false
    }
  }

  @Watch('wishlist')
  protected onWishlist (): void {
    if (this.wishlist) {
      this.fetchProductsOfWishlist()
    }
  }
}

export default Wishlist
